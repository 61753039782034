import { CustomRoutes }  from 'react-admin';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route } from "react-router-dom";
import {
  fetchHydra as baseFetchHydra,
  HydraAdmin,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
  ResourceGuesser
} from "@api-platform/admin";
import authProvider from "./utils/authProvider";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import CreatePrize from "./prizes/CreatePrize";
import EditPrize from "./prizes/EditPrize";
import showPrize from "./prizes/ShowPrize";
import ListPrize from "./prizes/ListPrize";
import ListPot from "./pots/ListPot";
import EditEntrant from "./entrants/EditEntrant";
import ListEntrant from "./entrants/ListEntrant";
import CreateEntrant from "./entrants/CreateEntrant";
import CreateMediaObject from "./media_objects/CreateMediaObject";
import EditMediaObject from "./media_objects/EditMediaObject";
import showMediaObject from "./media_objects/ShowMediaObject";
import ListEntries from "./entries/ListEntries";
import UploadEntrant from "./entrants/UploadEntrant";
import AdminLayout from "./components/Layout";
import EditPot from "./pots/EditPot";
import CreatePot from "./pots/CreatePot";
import ShowPot from "./pots/ShowPot";
import ShowEntries from "./entries/ShowEntries";
import ShowEntrant from "./entrants/ShowEntrant";
import LoginPage from "./components/LoginPage";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import CollectionsIcon from '@mui/icons-material/Collections';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const entrypoint: string = process.env.REACT_APP_API_ENTRYPOINT ?? '';

const getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {} as Headers;

const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Navigate to="/login" />;
};

const apiDocumentationParser = (setRedirectToLogin) => async () => {
  try {
    setRedirectToLogin(false);

    return await parseHydraDocumentation(entrypoint, { headers: getHeaders });
  } catch (result) {
    // @ts-ignore
    const { api, response, status } = result;
    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem("token");

    setRedirectToLogin(true);

    return {
      api,
      response,
      status,
    };
  }
};

const dataProvider = (setRedirectToLogin) => baseHydraDataProvider({
  entrypoint: entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser: apiDocumentationParser(setRedirectToLogin),
});

// Replace with your own API entrypoint
// For instance if https://example.com/api/books is the path to the collection of book resources, then the entrypoint is https://example.com/api
const Admin = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  return (
    <>
      <Helmet>
        <title>Admin Panel</title>
      </Helmet>
      <HydraAdmin
        layout={AdminLayout}
        dataProvider={dataProvider(setRedirectToLogin)}
        authProvider={authProvider}
        entrypoint={window.origin}
        loginPage={LoginPage}
      >
        {redirectToLogin ? (
          <CustomRoutes>
            <Route path="/" element={<RedirectToLogin />} />
            <Route path="/:any" element={<RedirectToLogin />} />
          </CustomRoutes>
        ) : (
          <>
            <ResourceGuesser name={"prizes"} create={CreatePrize} edit={EditPrize} show={showPrize} list={ListPrize} icon={EmojiEventsIcon} />
            <ResourceGuesser name={"pots"} create={CreatePot} edit={EditPot} show={ShowPot} list={ListPot} icon={CollectionsIcon} />
            <ResourceGuesser name={"entrants"} create={CreateEntrant} edit={EditEntrant} list={ListEntrant} show={ShowEntrant} icon={PersonPinIcon} />
            <ResourceGuesser name={"media_objects"} create={CreateMediaObject} edit={EditMediaObject} show={showMediaObject} icon={CloudUploadIcon} />
            <ResourceGuesser name={"entries"} list={ListEntries} show={ShowEntries} icon={ConfirmationNumberIcon} />
            <CustomRoutes>
              <Route path="/upload-entrants" element={<UploadEntrant />} />
            </CustomRoutes>
          </>
        )}
      </HydraAdmin>
    </>
  );
};

export default Admin;
